import React from 'react';
import Button from "../../components/common/Button";
import Text from "../../components/common/Text";
import {H3} from "../../components/common/Headlines/H3";
import {useGetAppInfo, useGetEnv} from "../../components/misc-shared/store/app-info/hooks";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {Container} from "react-bootstrap";
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import Toggle from "../../components/common/Toggle";
import ConnectedThreads from "./ConnectedThreads";

const SettingsPage = () => {
    const {loading: appInfoLoading, data: appInfoData} = useGetAppInfo();
    const {devModeIsActive} = useGetEnv();

    const {data: errorData, loading: errorLoading} = useRequestData({
            url: "/settings/error-logs",
            errorToastMsg: "Fehler beim Laden der Fehlermeldungen. ",
        }
    );

    const {data: infoData, loading: infoLoading} = useRequestData({
            url: "/settings/info-logs",
            errorToastMsg: "Fehler beim Laden der Infomeldungen. ",
        }
    );

    const {loading: scriptsLoading, refetch: scriptsRefetch} = useRequestData({
            url: "/settings/scripts",
            errorToastMsg: "Fehler beim Laden des Skripts. ",
            manual: true,
        }
    );

    const listenNfcPermission = async () => {
        try { // ask for nfc permission
            // eslint-disable-next-line no-undef
            const ndef = new NDEFReader();
            await ndef.scan();

            ndef.addEventListener("readingerror", () => {
            });

            ndef.addEventListener("reading", ({message, serialNumber}) => {
            });
        } catch (error) {
        }
    }
    return (<Container className="mb-5">
            <H3 className="mt-5">Entwicklermodus</H3>
            <Toggle
                setChecked={(checked) => {
                    window.localStorage.setItem('devMode', checked ? new Date() : '0');
                    window.location.reload();
                }}
                checked={devModeIsActive}
                label="Entwickler-Informationen ein- oder ausblenden"
            />

            <ConnectedThreads/>

            <H3 className="mt-5">App Version</H3>
            <Text isBlock>App: {process.env.REACT_APP_VERSION}</Text>
            <Text>Backend: </Text>{appInfoLoading ? <LoadingSpinner/> :
            <Text>{appInfoData && appInfoData.version}</Text>}

            <H3 className="mt-5">NFC Zugriff</H3>
            <Text>Falls NFC Zugriff deaktiviert ist klicken Sie hier: </Text>
            <Button
                text="NFC Zugriff erlauben"
                onClick={() => {
                    listenNfcPermission()
                }}
                className="mt-3"
            />

            <H3 className="mt-5">Error Logs</H3>
            {errorLoading ? <LoadingSpinner/> : <pre>{errorData}</pre>}

            <H3 className="mt-5">Info Logs</H3>
            {infoLoading ? <LoadingSpinner/> : <pre>{infoData}</pre>}

            <H3 className="mt-5">Hinterlegtes Skript ausführen</H3>
            <Button
                text="Skript ausführen"
                isLoading={scriptsLoading}
                onClick={scriptsRefetch}
            />
        </Container>
    );
};

export default SettingsPage;
