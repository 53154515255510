export const Colors = {
    primary: 'var(--color-primary)',
    primaryLight: 'var(--color-primary-light)',
    primaryHover: 'var(--color-primary-hover)',
    secondary: 'var(--color-secondary)',
    lightGrey: 'var(--color-light-grey)',
    backgroundGrey: 'var(--color-background-grey)',
    midGray: 'var(--color-mid-gray)',
    darkGray: 'var(--color-dark-gray)',
    white: 'var(--color-white)',
    font: 'var(--color-font)',
    success: 'var(--color-success)',
    danger: 'var(--color-danger)',
    warn: 'var(--color-warn)',
    chart: [
        '#C40038',
        '#b9ac07',
        '#40A126',
    ]
}

export const General = {
    boxShadow: 'var(--box-shadow)',
    borderRadius: 'var(--border-radius)',
}

export const FontSizes = {
    Small: 'var(--font-size-small)',
    Medium: 'var(--font-size-medium)',
    Large: 'var(--font-size-large)',
}
