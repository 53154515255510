import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import ListItem from "../../ListItem";
import PropTypes from 'prop-types';
import {StyledInput, StyledInputLabel, StyledInputWrapper} from "./StyledInput";
import Validation, {validationPropTypes} from "../../Validation";


export const InputTextSizes = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
}
const InputText = forwardRef(({
                                  type = "text",
                                  size = InputTextSizes.md,
                                  className = "",
                                  placeholder = "",
                                  value = "",
                                  setValue = () => {
                                  },
                                  readonly = false,
                                  hasBorder = true,
                                  onPressEnter = () => {
                                  },
                                  label = "",
                                  validation = {},
                                  onBlur = () => {
                                  },
                                  onFocus = () => {
                                  },
                              }, ref) => {
    const inputRef = useRef();
    const [inputIsModified, setInputIsModified] = useState(false)

    useImperativeHandle(ref, () => ({
        focus: () => {
            inputRef.current.focus();
        }
    }));

    const checkIfEnterIsPressed = (event) => {
        if (event.key === 'Enter') {
            onPressEnter()
        }
    }

    const getContent = () => {
        return <>
            {label && <StyledInputLabel size={size}>{label}</StyledInputLabel>}
            <StyledInputWrapper>
                <StyledInput
                    ref={inputRef}
                    type={type}
                    onChange={(e) => {
                        setValue(e.target.value)
                        setInputIsModified(true);
                    }}
                    onKeyDown={checkIfEnterIsPressed}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    autoComplete="off"
                    placeholder={placeholder}
                    value={value}
                    readOnly={readonly}
                    size={size}
                    $hasBorder={hasBorder}
                />
            </StyledInputWrapper>
            <Validation value={value} validation={validation} inputIsModified={inputIsModified}/></>
    }

    if (hasBorder) {
        return (
            <ListItem size={size} className={className}>
                {getContent()}
            </ListItem>
        );
    } else {
        return <div className={className}>{getContent()}</div>;
    }
});

InputText.propTypes = {
    type: PropTypes.string,
    size: PropTypes.oneOf(Object.values(InputTextSizes)),
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    readonly: PropTypes.bool,
    validation: PropTypes.shape(validationPropTypes),
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
};

export default InputText;
