import React, {useState, useEffect, useRef} from "react";
import ClickableArea from "../../ClickableArea";
import {Collapse} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import FilterDropdown from "./FilterDropdown";

const Filter = ({column}) => {
    const columnFilterValue = column.getFilterValue();
    const {filterVariant} = column.columnDef.meta ?? {};

    return filterVariant === 'range' ? (
        <div>
            <div className="flex space-x-2">
                {/* See faceted column filters example for min max values functionality */}
                <DebouncedInput
                    type="number"
                    value={(columnFilterValue)?.[0] ?? ''}
                    onChange={(value) =>
                        column.setFilterValue((old) => [value, old?.[1]])
                    }
                    placeholder={`Min`}
                    className="w-24 border shadow rounded"
                />
                <DebouncedInput
                    type="number"
                    value={(columnFilterValue)?.[1] ?? ''}
                    onChange={(value) =>
                        column.setFilterValue((old) => [old?.[0], value])
                    }
                    placeholder={`Max`}
                    className="w-24 border shadow rounded"
                />
            </div>
            <div className="h-1"/>
        </div>
    ) : filterVariant === 'select' ? (
        <select
            onChange={(e) => column.setFilterValue(e.target.value)}
            value={columnFilterValue?.toString()}
        >
            {/* See faceted column filters example for dynamic select options */}
            <option value="">All</option>
            <option value="complicated">complicated</option>
            <option value="relationship">relationship</option>
            <option value="single">single</option>
        </select>
    ) : (
        <DebouncedInput
            column={column}
            className="w-36 border shadow rounded"
            onChange={(value) => column.setFilterValue(value)}
            placeholder={`Filtern...`}
            type="text"
            value={(columnFilterValue ?? '')}
        />
        // See faceted column filters example for datalist search suggestions
    );
}

// A typical debounced input react component
const DebouncedInput = ({
                            column,
                            value: initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }) => {
    const filterDropdownRef = useRef();
    const [open, setOpen] = useState(false);
    const collapseRef = useRef(null); // Reference to the collapse element

    // Function to handle clicks outside of the collapse component
    const handleClickOutside = (event) => {
        if (collapseRef.current && !collapseRef.current.contains(event.target)) {
            setOpen(false); // Close the collapse when clicked outside
        }
    };

    // Use useEffect to add and clean up the click event listener
    useEffect(() => {
        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener when the component is unmounted or open changes
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open]);


    const [selectedValues, setSelectedValues] = useState([]);
    const [uniqueValues, setUniqueValues] = useState([])

    useEffect(() => {
        handleInputChange()
    }, []);

    const handleInputChange = (input) => {
        const values = [...new Set(column.getFacetedRowModel()
            .flatRows
            .map(row => row.getValue(column.id)))]

        if (!input) {
            setUniqueValues(values)
        } else {
            setUniqueValues(values.filter(value => value.toLowerCase().startsWith(input.toLowerCase())))
        }
    }

    const handleInputEnter = () => {
        setSelectedValues(uniqueValues);
        column.setFilterValue(uniqueValues.length ? uniqueValues : undefined);
        setOpen(false);
    };

    const handleCheckboxChange = (value) => {
        const newSelectedValues = selectedValues.includes(value)
            ? selectedValues.filter(v => v !== value)
            : [...selectedValues, value];
        setSelectedValues(newSelectedValues);
        column.setFilterValue(newSelectedValues.length ? newSelectedValues : undefined);
    };


    return (
        <>
            <ClickableArea handleClick={() => {
                setOpen(true)
                filterDropdownRef.current?.focusInput();
            }}>
                <FontAwesomeIcon
                    icon={faFilter}
                    size="xs"
                    color={column.getFilterValue() && column.getFilterValue().length ? "var(--color-secondary)" : "var(--color-font)"}
                />
            </ClickableArea>

            <div className="position-relative">
                <div className="position-absolute">
                    <Collapse in={open}>
                        <div ref={collapseRef}>
                            <FilterDropdown
                                ref={filterDropdownRef}
                                uniqueValues={uniqueValues}
                                selectedValues={selectedValues}
                                handleCheckboxChange={handleCheckboxChange}
                                handleInputChange={handleInputChange}
                                handleInputEnter={handleInputEnter}
                            />
                        </div>
                    </Collapse>
                </div>
            </div>
        </>
    );
}


export default Filter;
