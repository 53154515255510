import styled from "styled-components";

const StyledToggle = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .material-toggle {
    height: 22px;
    width: 40px;
  }

  .material-toggle input:empty {
    margin-left: -9999px;
  }

  .material-toggle input:empty ~ label {
    position: relative;
    float: left;
    width: 150px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .material-toggle input:empty ~ label:before,
  .material-toggle input:empty ~ label:after {
    position: absolute;
    display: block;
    content: ' ';
    -webkit-transition: all 250ms cubic-bezier(.4, 0, .2, 1);
    transition: all 250ms cubic-bezier(.4, 0, .2, 1);
  }

  .material-toggle input:empty ~ label:before {
    top: 3px;
    left: 0;
    width: 32px;
    height: 13px;
    border-radius: 12px;
    background-color: var(--color-background-grey);
  }

  input.switch:empty ~ label:after {
    top: 1px;
    left: -9px;
    bottom: 0.1em;
    margin-left: 0.1em;
    background-color: var(--color-white);
    width: 17px;
    height: 17px;
    border-radius: 50%;
    border: solid 2px;
    border-color: var(--color-white);
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .24), 0 3px 3px -2px rgba(0, 0, 0, .25), 0 1px 8px 0 rgba(0, 0, 0, .17);
  }

  .material-toggle input:checked ~ label:before {
    background-color: #1e88e5;
  }

  .material-toggle input:checked ~ label:after {
    left: 15px;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
  }
`

export default StyledToggle;
