import React from 'react';
import {Container} from "react-bootstrap";
import Jumbotron from "../../components/common/Jumbotron";
import {H2} from "../../components/common/Headlines/H2";
import Button, {ButtonSizes, ButtonTypes} from "../../components/common/Button";
import {faGears, faUsers} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

const DashboardPage = () => {
    const navigate = useNavigate();

    const items = ["Ebenen 7-13", "Bettenzentrale", "Maschinenpark"]

    return (
        <Container>
            {items.map(i => <Jumbotron>
                <H2>{i}</H2>
                <div className="d-flex">
                    <Button onClick={() => {
                        navigate('/planungsassistent/e7-13')
                    }} type={ButtonTypes.ghost} size={ButtonSizes.lg} Icon={faUsers} text="Planungsassistent"/>
                    <Button onClick={() => {
                        alert("Hier können später Mitarbeiter verwaltet werden. ")
                    }} type={ButtonTypes.ghost} size={ButtonSizes.lg} Icon={faGears} text="Personalverwaltung"/>
                </div>
            </Jumbotron>)}


        </Container>
    );
};

export default DashboardPage;
