import {createAsyncThunk} from "@reduxjs/toolkit";
import {toast} from "react-toastify";
import {getExceptionPayload} from "../../../../helpers/store";
import axios from "axios";

export const fetchAppInfo = createAsyncThunk(
    'general/fetchAppInfo',
    async (id, {rejectWithValue}) => {
        try {
            const response = await axios.get("/settings/app-info");
            return response.data;
        } catch (exception) {
            const errorMessage = getExceptionPayload(exception).message;
            toast.error(`Error fetching data: ${errorMessage}`);
            return rejectWithValue(getExceptionPayload(exception));
        }
    });
