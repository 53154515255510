import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const useRequestData = ({
                            url,
                            method = 'get',
                            errorToastMsg = "", // leave empty for manual error handling
                            manual,
                            headers = {},
                            onRequestSuccess = () => {
                            },
                        }) => {
    const [response, setResponse] = useState();
    const [data, setData] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);

    const fetchData = async (payload, overwrittenUrl = url) => {
        setLoading(true);
        setData(undefined);
        setResponse(undefined);
        try {
            let response;
            switch (method.toLowerCase()) {
                case 'post':
                    response = await axios.post(overwrittenUrl, payload, headers);
                    break;
                case 'delete':
                    response = await axios.delete(overwrittenUrl, headers);
                    break;
                case 'put':
                    response = await axios.put(overwrittenUrl, payload, headers);
                    break;
                case 'patch':
                    response = await axios.patch(overwrittenUrl, payload, headers);
                    break;
                default:
                    response = await axios.get(overwrittenUrl, headers);
            }
            setData(response?.data);
            setResponse(response);
            onRequestSuccess(response?.data);
            setError();
        } catch (error) {
            setData()
            setResponse()
            console.error(error)
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            setError(undefined);
            setData(undefined);
            setResponse(undefined);
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (error && errorToastMsg) {
            toast.error(errorToastMsg);
        }
    }, [error]);

    useEffect(() => {
        if (!manual) {
            fetchData();
        }
    }, [url]);

    const refetch = (payload, url) => {
        fetchData(payload, url);
    };

    return {data, error, loading, refetch, response};
};

export default useRequestData;
