import React, {useState, useEffect} from 'react';
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import {useNavigate, useParams} from 'react-router-dom';
import LoadingSpinner from "../../components/common/LoadingSpinner";
import {H1} from "../../components/common/Headlines/H1";
import {Container} from "react-bootstrap";
import Button, {ButtonTypes} from "../../components/common/Button";
import {toast} from "react-toastify";
import UserFields from "./components/UserFields";
import Footer from "../../components/common/Footer";

const UsersDetailPage = () => {
    let {id} = useParams();
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const {data, loading} = useRequestData({
            url: '/users/' + id,
            errorToastMsg: "Fehler beim Abholen des Benutzers.",
        }
    );
    useEffect(() => {
        if (data) {
            setUsername(data.username)
        }
    }, [data]);

    const [patchedObject, setPatchedObject] = useState([])
    const addPatchedField = (field, value) => {
        const newObject = {}
        newObject[field] = value;
        setPatchedObject({
            ...patchedObject,
            ...newObject,
        })
    }


    const {data: updateUserData, loading: updateUserLoading, refetch: updateUserRefetch} = useRequestData({
            url: '/users/' + id,
            errorToastMsg: "Fehler beim Speichern des Benutzers.",
            method: 'patch',
            manual: true,
        }
    );
    useEffect(() => {
        if (updateUserData) {
            toast.success('Benutzer gespeichert');
            navigate('/benutzer')
        }
    }, [updateUserData])


    if (loading) {
        return <LoadingSpinner/>
    }

    return (<div className="d-flex flex-column min-vh-100 justify-content-between">
        <Container style={{marginTop: "120px"}}>
            <H1>Benutzer {data?.username}</H1>

            <UserFields
                username={username}
                setUsername={(value) => {
                    setUsername(value);
                    addPatchedField('username', value);
                }}
                password={password}
                setPassword={(value) => {
                    setPassword(value);
                    addPatchedField('password', value);
                }}
            />



        </Container>

            <Footer>
                <Container className="d-flex justify-content-between">
                    <Button
                        text="Abbrechen"
                        onClick={() => {
                            navigate('/benutzer')
                        }}
                        type={ButtonTypes.secondary}
                    />
                    <Button
                        text="Speichern"
                        onClick={() => {
                            updateUserRefetch(patchedObject)
                        }}
                        isLoading={updateUserLoading}
                        disabled={Object.keys(patchedObject).length === 0}
                    />
                </Container>
            </Footer>


        </div>
    );
};

export default UsersDetailPage;
