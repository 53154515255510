import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Colors} from "../../theme";
import {useGetAppInfo} from "./store/app-info/hooks";
import LoadingSpinner from "../common/LoadingSpinner";
import {H3} from "../common/Headlines/H3";
import {createHashRouter, RouterProvider} from "react-router-dom";
import Modal from "../common/Modal";
import {routes} from "../../routes";

class ErrorBoundary extends React.Component {
    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo)
    }

    render() {
        return this.props.children;
    }
}



const router = createHashRouter(routes, {
    basename: process.env.PUBLIC_URL,
});


const StyledApplication = styled.div`
  font-family: "Trebuchet MS", sans-serif;
  color: ${Colors.font};
  min-height: 100vh;
  @media screen {
      //background: ${Colors.lightGrey};
  }

  :focus {
    outline: none;
  }

  .form-control:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  a {
    color: ${Colors.font};
  }
`;

const ApplicationWrapper = ({children}) => {
    const {loading: appInfoLoading, data: appInfoData} = useGetAppInfo(false);
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        if (appInfoData && appInfoData.version) {
            if (appInfoData.version !== process.env.REACT_APP_VERSION) {
                setShowModal(true);
            }
        }
    }, [appInfoData])

    return (
        <StyledApplication>
            <ErrorBoundary>
                <RouterProvider router={router}/>
                <Modal show={showModal} onConfirm={() => {
                    window.location.reload()
                }} onConfirmText="Aktualisieren" header={<H3>Neue Version verfügbar</H3>}>
                    <>
                        <p>Eine neue Version ist verfügbar, bitte klicken Sie auf "Aktualisieren". </p>
                    </>
                </Modal>
                {appInfoLoading ? <LoadingSpinner/> : children}
            </ErrorBoundary>
        </StyledApplication>
    );
};

export default ApplicationWrapper;
