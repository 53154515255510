import React from 'react';
import Text from "../Text";
import ClickableArea from "../ClickableArea";
import StyledToggle from "./StyledToggle";

const Toggle = ({
                   checked = false,
                   setChecked = () => {
                   },
                   label
               }) => {
    const toggleChecked = () => {
        setChecked(!checked)
    }

    return (
        <StyledToggle>
            <div className="material-toggle">
                <input type="checkbox" id="toggle" name="toggle" checked={checked} className="switch"
                       onChange={toggleChecked}/>
                <label htmlFor="toggle"/>
            </div>
            {label && <ClickableArea handleClick={toggleChecked}>
                <Text>{label}</Text>
            </ClickableArea>}
        </StyledToggle>
    );
};

export default Toggle;
