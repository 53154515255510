import React from 'react';
import PropTypes from 'prop-types';
import {Container, Modal as BootstrapModal} from 'react-bootstrap';
import Button, {ButtonTypes} from '../Button';
import LoadingSpinner from '../LoadingSpinner';
import {StyledModal} from "./StyledModal";
import Footer from "../Footer";

export const ModalSizes = {
    sm: 'sm',
    lg: 'lg',
    xl: 'xl',
    full: 'full'
}

const Modal = ({
                   show,
                   setShow,
                   onConfirm = () => {
                   },
                   onConfirmText = '',
                   onCancel = () => {
                   },
                   onCancelText = "",
                   children,
                   isLoading = false,
                   isPrimaryButtonDisabled = false,
                   header = null,
                   size = ModalSizes.lg,
                   hideHeaderAndFooter = false, // hide header and footer
               }) => {
    const hide = () => setShow(false);

    return (
        <StyledModal>
            <BootstrapModal
                show={show}
                onHide={hide}
                centered
                size={size}
                backdrop="static"
                dialogClassName={size === ModalSizes.full ? 'modal-fullscreen' : ''}

            >
                {!hideHeaderAndFooter && <BootstrapModal.Header closeButton>{header}</BootstrapModal.Header>}
                <BootstrapModal.Body className={hideHeaderAndFooter && 'p-0'}>
                    {children}
                </BootstrapModal.Body>



                {!hideHeaderAndFooter && <Footer className="mt-0">
                    <Container>
                    <div
                        className={`d-flex px-3 w-100 ${isLoading ? 'justify-content-center' : 'justify-content-between'} align-items-center`}>
                        {isLoading ? <LoadingSpinner/> : (
                            <>
                                <div>
                                {onCancelText &&
                                    <Button type={ButtonTypes.secondary} text={onCancelText} onClick={onCancel}/>}
                                </div>
                                <div className="d-flex justify-content-end">
                                    {onConfirmText &&
                                    <Button type={ButtonTypes.primary} text={onConfirmText} onClick={onConfirm}
                                            disabled={isPrimaryButtonDisabled}/>}
                                </div>
                            </>
                        )}
                    </div>
                    </Container>
                </Footer>}
            </BootstrapModal>
        </StyledModal>
    );
};

Modal.propTypes = {
    show: PropTypes.bool,
    setShow: PropTypes.func,
    onConfirm: PropTypes.func,
    onConfirmText: PropTypes.string,
    onCancel: PropTypes.func,
    onCancelText: PropTypes.string,
    children: PropTypes.element,
    isLoading: PropTypes.bool,
    isPrimaryButtonDisabled: PropTypes.bool,
    header: PropTypes.element,
    size: PropTypes.oneOf(Object.values(ModalSizes)),
    hideHeaderAndFooter: PropTypes.bool,
};

export default Modal;
