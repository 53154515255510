import React from 'react';
import {H3} from "../../components/common/Headlines/H3";
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import Text from "../../components/common/Text";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const ConnectedThreads = () => {
    const {data, loading} = useRequestData({
            url: "/settings/stats",
            errorToastMsg: "Fehler beim Laden der Stats. ",
        }
    );

    return (
        <div>
            <H3 className="mt-5">Connected Threads Datenbank</H3>
            <Text>Derzeit aktive Verbindungen: {data?.connectedDbThreads || '-'}</Text>
            {loading && <LoadingSpinner />}
        </div>
    );
};

export default ConnectedThreads;
