import React from 'react';
import Filter from "./Filter";
import {flexRender} from "@tanstack/react-table";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledCustomSpanWrapper = styled.div`
  span {
    padding-bottom: 0 !important;
    display: inline-block;
    margin-bottom: 15px;
  }
`

const Thead = ({table}) => {
    return (<thead>
    {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
                return (
                    <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className={header.column.columnDef.className}
                    >
                        {header.isPlaceholder ? null : (
                            <div className="d-flex">
                                {header.column.getCanFilter() ? (
                                    <div className="d-print-none me-2">
                                        <Filter column={header.column}/>
                                    </div>
                                ) : null}
                                <div
                                    className={
                                        header.column.getCanSort()
                                            ? 'cursor-pointer'
                                            : ''
                                    }
                                    onClick={header.column.getToggleSortingHandler()}
                                    title={
                                        header.column.getCanSort()
                                            ? header.column.getNextSortingOrder() === 'asc'
                                                ? 'Sort ascending'
                                                : header.column.getNextSortingOrder() === 'desc'
                                                    ? 'Sort descending'
                                                    : 'Clear sort'
                                            : undefined
                                    }
                                >
                                    <StyledCustomSpanWrapper>
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                                    </StyledCustomSpanWrapper>
                                    {{
                                        asc: <FontAwesomeIcon
                                            icon={faCaretUp}
                                            className="ms-1"
                                        />,
                                        desc: <FontAwesomeIcon
                                            icon={faCaretDown}
                                            className="ms-1"
                                        />,
                                    }[header.column.getIsSorted()] ?? null}
                                </div>
                            </div>
                        )}
                    </th>
                )
            })}
        </tr>
    ))}
    </thead>);
};

export default Thead;
