import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {fetchUser} from "./thunks";

export const useGetUser = (fetch = true) => {
    const dispatch = useDispatch();
    const {data, loading, error} = useSelector(state => state.user);

    useEffect(() => {
        if (fetch) {
            dispatch(fetchUser());
        }
    }, []);

    const forceFetchUser = ()=>{
        dispatch(fetchUser());
    }

    return {
        user: data, loading, error, forceFetchUser
    };
}
