import React from 'react';
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Text from "../../common/Text";
import StyledHeader from "../Header/StyledHeader";

const HeaderBackTo = ({title="", target=""}) => {
    const navigate = useNavigate();

    if(!title) {
        title="Zurück zur Übersicht"
    }

    if(!target){
        target="/";
    }

    const go = () => {
        navigate(target)
    };

    return (
        <StyledHeader>
                <div className="d-flex align-items-center" onClick={go} role="button">
                    <div className="me-2">
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </div>
                    <Text>{title}</Text>
                </div>
        </StyledHeader>
    );
};

export default HeaderBackTo;
