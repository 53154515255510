import moment from "moment";

export const getNextCalendarWeek = ({year, week}) => {
    const weeksInYear = new Date(year, 11, 31).getDay() === 4 ||
    new Date(year, 11, 31).getDay() === 3 &&
    new Date(year + 1, 0, 1).getDay() === 4
        ? 53 : 52;

    if (week < weeksInYear) {
        return {year, week: week + 1};
    } else {
        return {year: year + 1, week: 1};
    }
};

export const getPrevCalendarWeek = ({year, week}) => {
    if (week > 1) {
        return {year, week: week - 1};
    } else {
        // Move to the last week of the previous year
        const previousYear = year - 1;
        const lastWeek = getWeeksInYear(previousYear);
        return {year: previousYear, week: lastWeek};
    }
};


export const getCurrentCalendarWeek = () => {
    const now = new Date();
    now.setUTCDate(now.getUTCDate() + 3 - (now.getUTCDay() + 6) % 7);
    const weekYear = now.getUTCFullYear();
    const firstDayOfYear = new Date(Date.UTC(weekYear, 0, 1));
    const weekNumber = Math.ceil(((now - firstDayOfYear) / 86400000 + 1) / 7);

    return {year: weekYear, week: weekNumber};
}

export const getMondayOfWeek = ({week, year}) => {
    const mondayOfCalendarWeek = moment()
        .year(year)
        .week(week)
        .day(1)
        .toDate();

    return mondayOfCalendarWeek;
}

const getWeeksInYear = (year) => {
    return new Date(year, 11, 31).getDay() === 4 ||
    (new Date(year, 11, 31).getDay() === 3 && new Date(year + 1, 0, 1).getDay() === 4)
        ? 53 : 52;
}



