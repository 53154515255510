import React, {useEffect, useState} from 'react';
import Jumbotron from "../../../components/common/Jumbotron";
import {H4} from "../../../components/common/Headlines/H4";
import Text from "../../../components/common/Text";
import DroppableBox from "./DropableBox";
import {Row, Col} from "react-bootstrap";

const StationManagement = ({workstations = [], onDragEmployee, onDeleteEmployee, assignments}) => {
    const getEntriesPerStation = (stationId) => {
        const assignmentsPerStation = assignments.filter(a => {
            return a.station.id === stationId
        })

        return assignmentsPerStation
    }

    return (
        <Row>
            {workstations.map((ws) => <Col sm={3} md={4}>
                <Jumbotron>
                    <H4>{ws.name}</H4>
                    <div className="d-flex gap-3">
                        {ws.stations.map(s => <div style={{minWidth: "100px", textAlign: "center"}}>
                            <DroppableBox
                                items={getEntriesPerStation(s.id)}
                                onDragEmployee={(employeeId) => {
                                    onDragEmployee(employeeId, s.id)
                                }}
                                onDeleteEmployee={(availabilityEntry)=>{
                                    onDeleteEmployee(availabilityEntry, s.id)
                                }}
                            />
                            <Text>{s.name} <span className="d-print-none">({s.requiredHours}h)</span></Text>
                        </div>)}
                    </div>
                </Jumbotron>
            </Col>)}
        </Row>
    );
};

export default StationManagement;
