import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const StyledListItem = styled.div`
  border-radius: var(--border-radius);
  background: var(--color-white);
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.1);
  border-left: 15px solid var(--color-primary);
  margin-bottom: ${({$marginBottom}) => $marginBottom};
  padding-right: 10px;
  width: 100%;

  input:focus {
    outline: none;
  }
`;

const ListItem = ({children, className = "", marginBottom = "25px"}) => {
    return (
        <StyledListItem className={className} $marginBottom={marginBottom}>
            {children}
        </StyledListItem>
    );
};

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  marginBottom: PropTypes.string,
};

export default ListItem;
