import styled, { css } from "styled-components";
import {ButtonSizes, ButtonTypes} from "./index";

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  border-radius: 10px;
  transition: background-color 0.3s ease-in-out;

  ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.3;
        cursor: not-allowed;
      `}

  ${(props) => {
    switch (props.type) {
      case ButtonTypes.primary:
        return css`
          background-color: var(--color-primary);
          color: var(--color-white);

          &:hover {
            background-color: var(--color-primary);
          }
        `;
      case ButtonTypes.secondary:
        return css`
          background-color: ${props.disabled ? 'var(--color-light-grey)' : 'var(--color-white)'};
          border: ${props.disabled ? null : `1px solid var(--color-dark-gray)`};
          color: var(--color-font);

          &:hover {
            background: var(--color-light-grey);
            color: var(--color-font);
          }
        `;
      case ButtonTypes.ghost:
        return css`
          color: inherit;
          text-decoration: underline;
          text-decoration-color: transparent;
          text-decoration-thickness: 1px;
          transition: text-decoration-color 0.3s ease-out;
          background-color: transparent;

          &:hover {
            text-decoration-color: var(--color-font);
          }
        `;
      default:
        return null;
    }
  }}

    // sizes
  ${(props) => {
    switch (props.size) {
      case ButtonSizes.sm:
        return css`
          font-size: var(--font-size-small);
          padding: 10px;
        `;
      case ButtonSizes.md:
        return css`
          min-width: 100px;
          min-height: 30px;
          font-size: var(--font-size-medium);
          padding: 15px;
        `;
      case ButtonSizes.lg:
        return css`
          min-width: 275px;
          min-height: 65px;
          font-size: var(--font-size-large);
          padding: 20px;
        `;
      default:
        return null;
    }
  }}
`;

export default StyledButton;
