import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {fetchAppInfo} from "./thunks";

export const useGetAppInfo = (fetch=true) => {
    const dispatch = useDispatch();
    const {data, loading, error} = useSelector(state => state.appInfo); // version is not set into data object in state @todo

    useEffect(() => {
        if(fetch){
            dispatch(fetchAppInfo());
        }
    }, []);

    return {
        data, loading, error,
    };
}

export const useGetEnv = () => {
    const devModeIsActive = useSelector(state => state.appInfo.devModeIsActive);
    const liegenschaft = useSelector(state => state.appInfo.liegenschaft);

    return {
        devModeIsActive,
        liegenschaft,
    };
}
