import React, {useEffect, useState} from 'react';
import Text from "../Text";
import PropTypes from "prop-types";

export const validationPropTypes = {
    required: PropTypes.bool,
    validationRegex: PropTypes.instanceOf(RegExp),
    setValid: PropTypes.func,
    errorMessage: PropTypes.string,
    validateOnInit: PropTypes.bool,
};

const Validation = ({
                        value,
                        validation: {
                            required = false,
                            regex = /.+/, // any symbol
                            setValid = () => {
                            },
                            errorMessage = 'Dieses Feld ist fehlerhaft. ',
                            validateOnInit = false,
                        },
                        inputIsModified=false,
                    }) => {
    const isValid = () => regex.test(value);
    const [localValid, setLocalValid] = useState()

    useEffect(() => {
        setValid(isValid());

        if (inputIsModified || validateOnInit) {
            setLocalValid(isValid())
        }
    }, [value])

    if (!required) {
        return null;
    }

    return (
        <div>
            {localValid === false &&
                <Text className="ps-2" fontSize="14px" color="var(--color-danger)">{errorMessage}</Text>}
        </div>
    );
};

Validation.propTypes = {
    value: PropTypes.string,
    validation: PropTypes.shape(validationPropTypes),
};
export default Validation;
