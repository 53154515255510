import React from 'react';
import {useDrag} from 'react-dnd';
import Text from "../../../components/common/Text";
import {Colors} from "../../../theme";
import styled from "styled-components";

const StyledDraggableItem = styled.div`
  opacity: ${({isDragging}) => isDragging ? 0.5 : 1};
  padding: 8px;
  margin-bottom: 4px;
  background-color: ${Colors.primaryLight};
  cursor: move;
  border-radius: 8px;
`
const DraggableItem = ({name, id, hoursMetaInfos= {}}) => {
    const [{isDragging}, dragRef] = useDrag({
        type: 'ITEM',
        item: {name, id},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <StyledDraggableItem
            ref={dragRef}
            isDragging={isDragging}
        >
            <div>
                <Text isBlock>{name}</Text>
                <Text fontSize="12px">
                    Heute: {hoursMetaInfos.spentHoursPerDay}h, Woche: {hoursMetaInfos.spentHoursPerWeek}/{hoursMetaInfos.hoursPerWeek}h
                </Text>
            </div>
        </StyledDraggableItem>
    );
}

export default DraggableItem;
