import React, {useState} from 'react';
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight, faPrint} from "@fortawesome/free-solid-svg-icons";
import {H1} from "../../components/common/Headlines/H1";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import OrganizeWeekdays from "./OrganizeWeekdays";
import {getCurrentCalendarWeek, getNextCalendarWeek, getPrevCalendarWeek} from "../../services/date.service";
import ClickableArea from "../../components/common/ClickableArea";
import Button from "../../components/common/Button";
import Alert from "../../components/common/Alert";

const ManageSchedulesPage = () => {
    const [calendarWeek, setCalendarWeek] = useState(getCurrentCalendarWeek())

    return (
        <DndProvider backend={HTML5Backend}>
            <Container fluid>
                <div className="d-flex justify-content-between mb-5 mt-5">
                    <ClickableArea handleClick={() => {
                        setCalendarWeek(getPrevCalendarWeek(calendarWeek))
                    }} className="d-print-none">
                        <FontAwesomeIcon icon={faChevronLeft} size="2x"/>
                    </ClickableArea>
                    <H1>KW {calendarWeek.week}/{calendarWeek.year}</H1>
                    <ClickableArea handleClick={() => {
                        setCalendarWeek(getNextCalendarWeek(calendarWeek))
                    }} className="d-print-none">
                        <FontAwesomeIcon icon={faChevronRight} size="2x"/>
                    </ClickableArea>
                </div>

                <Alert className="d-print-none">
                    Hier kommen ggf Hinweise, z.B. Mitarbeiter XY hat letztes Wochenende bereits gearbeitet.
                </Alert>

                <div className="d-flex justify-content-end d-print-none">
                    <Button onClick={()=>{window.print()}} text="Wochenplan drucken" Icon={faPrint} />
                </div>

                <OrganizeWeekdays calendarWeek={calendarWeek} />
            </Container>
        </DndProvider>
    );
};

export default ManageSchedulesPage;
