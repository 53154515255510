import React from 'react';
import PropTypes from 'prop-types';

export const AlertTypes = {
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'info',
    light: 'light',
    dark: 'dark',
};

const Alert = ({children, type = AlertTypes.warning, className = ""}) => {
    return (
        <div className={`alert alert-${type} ${className}`} role="alert">
            {children}
        </div>
    );
};

Alert.propTypes = {
    children: PropTypes.node.isRequired,
    type: PropTypes.oneOf(Object.values(AlertTypes)),
    className: PropTypes.string,
};

export default Alert;
