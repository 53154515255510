import React from 'react';
import StyledFooter from "./StyledFooter";

const Footer = ({children, className="mt-4"}) => {
    return (
        <StyledFooter className={className}>
            {children}
        </StyledFooter>
    );
};

export default Footer;
