import React from 'react';
import {createColumnHelper,} from '@tanstack/react-table'
import LoadingSpinner from "../../components/common/LoadingSpinner";
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import Text from "../../components/common/Text";
import Table from "../../components/common/Table";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import Button from "../../components/common/Button";
import {H1} from "../../components/common/Headlines/H1";

const columnHelper = createColumnHelper()

const UsersOverviewPage = () => {
    const navigate = useNavigate()
    const {data, loading} = useRequestData({
            url: '/users',
            errorToastMsg: "Fehler beim Abholen der Benutzer.",
        }
    );

    const columns = [
        columnHelper.accessor(row => row.id, {
            id: 'id',
            cell: info => info.getValue(),
            header: () => <Text>ID</Text>,
            enableColumnFilter: false,
        }),
        columnHelper.accessor(row => row.username, {
            id: 'username',
            cell: info => info.getValue(),
            header: () => <Text>Benutzername</Text>,
        }),
        columnHelper.accessor(row => row.id, {
            id: 'showDetails',
            cell: info => <Link to={"/benutzer/" + info.getValue()}><FontAwesomeIcon icon={faEye}/></Link>,
            header: () => <Text>Details</Text>,
            enableColumnFilter: false,
        }),
    ];

    if (loading) {
        return <LoadingSpinner/>;
    }


    return <Container>

        <div className="d-flex justify-content-between" style={{marginTop: "120px"}}>
            <H1>Benutzer Übersicht</H1>
            <div>
                <Button text="Benutzer anlegen" onClick={() => {
                    navigate('/benutzer/neu')
                }}/>
            </div>
        </div>

        {data && data.length === 0 && <div className="pt-3">
            <Text>Aktuell liegen keine Benutzer vor.</Text>
        </div>}

        {data && !!data.length && <Table columns={columns} data={data}/>}
    </Container>
};

export default UsersOverviewPage;
