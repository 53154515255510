import axios from 'axios'
import humps from 'humps'

axios.interceptors.request.use(
    config => {
        config.headers['Authorization'] = 'Basic ' + btoa(process.env.REACT_APP_API_USER + ':' + process.env.REACT_APP_API_PW)
        config.headers['Custom-Auth'] = window.localStorage.getItem('loginToken') || '';
        config.baseURL = process.env.REACT_APP_API_URL;
        config.withCredentials = false;


        config.data = humps.decamelizeKeys(config.data);

        return config
    }
)

axios.interceptors.response.use(
    config => {
        config.data = humps.camelizeKeys(config.data);

        return config
    }
)

