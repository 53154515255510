import React from "react";
import StyledButton from "./StyledButton";
import LoadingSpinner from "../LoadingSpinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export const ButtonTypes = {
    primary: 'primary',
    secondary: 'secondary',
    ghost: 'ghost',
};

export const ButtonSizes = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
};

const Button = ({
                    onClick = undefined,
                    type = ButtonTypes.primary,
                    disabled = false,
                    size = ButtonSizes.md,
                    className = "",
                    isLoading = false,
                    text = "",
                    Icon = null,
                }) => {
    const getIconSize = () =>{
        switch (size) {
            case ButtonSizes.sm:
                return "1x";
            case ButtonSizes.md:
                return "1x";
            default:
                return "2x";
        }
    }

    return (
        <StyledButton
            className={className}
            type={type}
            disabled={disabled}
            size={size}
            onClick={onClick}
        >
            {Icon && <FontAwesomeIcon icon={Icon} size={getIconSize()}/>}
            {text}
            {isLoading && <LoadingSpinner/>}
        </StyledButton>
    );
}

Button.propTypes = {
    onClick: PropTypes.func.isRequired,
    type: PropTypes.oneOf(Object.values(ButtonTypes)),
    disabled: PropTypes.bool,
    size: PropTypes.oneOf(Object.values(ButtonSizes)),
    className: PropTypes.string,
    isLoading: PropTypes.bool,
    text: PropTypes.string,
    Icon: PropTypes.object,
};
export default Button;
