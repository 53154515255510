import styled from "styled-components";

const StyledHeader = styled.div`
  .by-welcome {
    font-size: 48px;
    font-weight: 700;
  }

  .by-date, .by-logout, .by-soz-logo {
    display: inline-block;
  }

  @media (max-width: 767px) {
    .by-soz-logo {
      display: none;
    }
  }
  @media print {
    display: none;
  }
  
  .by-soz-logo {
    width: 250px;
  }
`;

export default StyledHeader;
