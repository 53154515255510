import React, {useEffect, useState} from 'react';
import Select from "react-select";
import Button from "../../../components/common/Button";
import Modal from "../../../components/common/Modal";

const UserManagement = ({employees = [], setEmployees}) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [options, setOptions] = useState([])

    useEffect(() => {
        if (employees) {
            setOptions(employees.map(e => ({
                label: e.name,
                value: e.id,
            })))
            setSelectedEmployees(employees.map(e => ({
                label: e.name,
                value: e.id,
            })))
        }
    }, [employees])

    return (
        <>
            <Button
                onClick={() => {
                    setShowModal(true)
                }}
                text="Verfügbare Mitarbeiter an diesem Tag verwalten"
            />

            <Modal show={showModal} setShow={setShowModal} onConfirmText="Schließen" onConfirm={() => {
                setShowModal(false)
                setEmployees(selectedEmployees.map(selectedEmployee => {
                    return employees.find(e => e.id === selectedEmployee.value)
                }));
            }}>
                <Select
                    value={selectedEmployees}
                    onChange={(v) => {
                        setSelectedEmployees(v);
                    }}
                    options={options || []}
                    placeholder="Bitte Reinigungskräfte auswählen, die an diesem Tag zur Verfügung stehen"
                    isSearchable
                    isMulti
                    isLoading={false}
                    isClearable={false}
                    styles={{
                        container: (styles) => ({
                            ...styles,
                        }),
                        menuList: (styles) => ({
                            ...styles,
                            boxShadow: "var(--box-shadow)",
                        }),
                        control: (styles) => ({
                            ...styles,
                            boxShadow: "none",
                        }),
                        dropdownIndicator: (styles) => ({
                            ...styles,
                            color: "var(--color-font)",
                        }),
                        indicatorSeparator: (styles) => ({
                            ...styles,
                            display: "none"
                        }),
                        option: (styles, {isFocused, isSelected}) => ({
                            ...styles,
                            cursor: "pointer",
                            backgroundColor: isSelected || isFocused ? "var(--color-primary-light)" : "var(--color-white)",
                            color: "var(--color-font)",
                        }),
                        placeholder: (styles) => ({
                            ...styles,
                            color: "var(--color-font)",
                        })
                    }}
                />
            </Modal>
        </>
    );
};

export default UserManagement;
