import {configureStore} from '@reduxjs/toolkit'
import appInfoSlice from '../components/misc-shared/store/app-info/slice';
import userSlice from '../components/misc-shared/store/user/slice';

export const store = configureStore({
    reducer: {
        appInfo: appInfoSlice,
        user: userSlice,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})
