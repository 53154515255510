import React, {useEffect, useState} from 'react';
import Input, {InputTextSizes} from "../../components/common/Inputs/InputText";
import Button, {ButtonSizes} from "../../components/common/Button";
import {useNavigate} from "react-router-dom";
import ContentWrapper from "../../components/misc-shared/ContentWrapper";
import {Container} from "react-bootstrap";
import Footer from "../../components/common/Footer";
import {H3} from "../../components/common/Headlines/H3";
import Text from "../../components/common/Text";
import Dropdown, {DropdownSizes} from "../../components/common/Dropdown";
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import {useGetUser} from "../../components/misc-shared/store/user/hooks";
import styled from "styled-components";
import bg from "../../assets/img/bg-white.jpg";
import logo from "../../assets/img/logo-ssb_diff.svg";
import {Colors, General} from "../../theme";
import {H1} from "../../components/common/Headlines/H1";

const StyledLoginPage = styled.div`
  background-image: url(${bg});
  background-size: cover;
  background-position: center center;
  box-shadow: ${General.boxShadow};

  .login-wrapper {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background: ${Colors.primaryLight};
    padding: 20px;
    border-radius: 20px;
  }
`;

const AdminLoginPage = () => {
    const navigate = useNavigate();
    const {forceFetchUser, user} = useGetUser(false)

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();


    const {data, loading, refetch: doLogin} = useRequestData({
            url: "/users/login",
            method: 'POST',
            manual: true,
            errorToastMsg: 'Fehler beim Anmelden: Benutzername oder Passwort falsch. ',
        }
    );

    const {data:users, loading:usersLoading} = useRequestData({
            url: "/users",
            errorToastMsg: 'Fehler beim Laden der Benutzer',
        }
    );

    useEffect(() => {
        if (data) {
            window.localStorage.setItem('loginToken', data.loginToken);
            forceFetchUser()
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            navigate("/")
        }
    }, [user]);

    const login = () => {
        doLogin({username, password})
    }

    return (
        <StyledLoginPage className="d-flex flex-column min-vh-100">
            <ContentWrapper className="pt-5">
                <div className="login-wrapper">

                    <H1>Reinigungsauftrag</H1>

                    <H3 className="my-5">Bitte geben Sie Ihren Login ein: </H3>

                    {!usersLoading && <>
                        <Dropdown
                            size={DropdownSizes.lg}
                            setSelectedValue={(value)=>{
                                setUsername(value.label)
                            }}
                            options={users?.map(u=>({label: u.username, value: u.username}))}
                        />
                        <Input
                            type="password"
                            value={password}
                            setValue={setPassword}
                            placeholder="Passwort"
                            size={InputTextSizes.lg}
                            onPressEnter={login}/>
                        <Button
                            text="Anmelden"
                            className="w-100"
                            onClick={login}
                            size={ButtonSizes.lg}
                            isLoading={loading}
                        />
                    </>}

                </div>
            </ContentWrapper>
            <Footer>
                <Container className="d-flex justify-content-between align-items-end">
                    <img src={logo} alt="logo" style={{width: "300px"}}/>
                    <Text isBlock>Ein Produkt der Sozialstiftung Bamberg. </Text>
                </Container>
            </Footer>
        </StyledLoginPage>
    );
};

export default AdminLoginPage;
