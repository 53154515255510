import React from 'react';
import Text from "../Text";

const TablePagination = ({
    table
                         }) => {
    return (<div className="d-flex justify-content-end">
        <div className="d-flex flex-column align-items-end me-2" style={{lineHeight: "12px"}}>
            <Text fontSize="12px">Seite{' '}
                {table.getState().pagination.pageIndex + 1} von{' '}
                {table.getPageCount().toLocaleString()}</Text>

            <div>
                <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                        table.setPageSize(Number(e.target.value))
                    }}
                    style={{fontSize: "12px", marginRight: "5px"}}
                >
                    {[10, 20, 40, 100, 500, 1000].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            {pageSize}
                        </option>
                    ))}
                </select>
                <Text fontSize="12px">{' '}von{' '}
                    {table.getRowCount().toLocaleString()} Einträgen</Text>
            </div>
        </div>
        <nav aria-label="Seiten Navigation">
            <ul className="pagination pagination-sm">
                {/*<li className={`page-item ${!table.getCanPreviousPage() && 'disabled'}`}
                                onClick={() => table.getCanPreviousPage() && table.firstPage()}>
                                <a className="page-link" href="#" onClick={(e) => e.preventDefault()}>{'Anfang'}</a>
                            </li>*/}
                <li className={`page-item ${!table.getCanPreviousPage() && 'disabled'}`}
                    onClick={() => table.getCanPreviousPage() && table.previousPage()}>
                    <a className="page-link" href="/" onClick={(e) => e.preventDefault()}>{'<'}</a>
                </li>
                {/*<li className="page-item">
                                <a className="page-link" href="#" onClick={(e) => e.preventDefault()}>
                                    <input
                                        type="number"
                                        min="1"
                                        max={table.getPageCount()}
                                        defaultValue={table.getState().pagination.pageIndex + 1}
                                        onChange={e => {
                                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                                            table.setPageIndex(page)
                                        }}
                                        style={{height: "20px"}}
                                    />
                                </a>
                            </li>*/}
                <li className={`page-item ${!table.getCanNextPage() && 'disabled'}`}
                    onClick={() => table.getCanNextPage() && table.nextPage()}>
                    <a className="page-link" href="/" onClick={(e) => e.preventDefault()}>{'>'}</a>
                </li>
                {/*<li className={`page-item ${!table.getCanNextPage() && 'disabled'}`}
                                onClick={() => table.getCanNextPage() && table.lastPage()}>
                                <a className="page-link" href="#" onClick={(e) => e.preventDefault()}>{'Ende'}</a>
                            </li>*/}
            </ul>
        </nav>


    </div>);
};

export default TablePagination;
