import React from 'react';
import ReactDOM from 'react-dom/client';

// css
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'
import "react-datepicker/dist/react-datepicker.css";
import 'moment/locale/de';
import "./components/misc-shared/http.interceptor";
import "./theme/theme.css";
import {store} from './store/store'
import {Provider} from 'react-redux'
import {ToastContainer} from 'react-toastify';
import ApplicationWrapper from "./components/misc-shared/ApplicationWrapper";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <ApplicationWrapper>
            <ToastContainer limit={1}/>
        </ApplicationWrapper>
    </Provider>
);
