import React from 'react';
import Container from 'react-bootstrap/Container';
import {useLocation, useNavigate} from "react-router-dom";
import Button, {ButtonSizes, ButtonTypes} from "../../common/Button";
import Logo from "../Logo";
import HeaderBackTo from "./HeaderBackTo";
import {Col, Row} from "react-bootstrap";
import ClickableArea from "../../common/ClickableArea";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";

const RaNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const getHeaderInfos = () => {
        const pn = location.pathname;
        if (pn.startsWith("/berichte/")) { // Bericht Details & add/create
            return {
                title: "Zurück zur Berichts-Übersicht",
                target: "/berichte"
            }
        } else if (pn.startsWith("/benutzer/")) { // benutzer add or edit
            return {
                title: "Zurück zur Benutzer-Übersicht",
                target: "/benutzer"
            }
        } else {
            return {
                title: "Zurück zur Übersicht",
                target: "/"
            }
        }
    }

    return (<Container className="mb-5 d-print-none" fluid>
            <Row>
                <Col sm={4} className="pt-3">
                    {location.pathname === "/" ?
                        <ClickableArea handleClick={()=>{navigate('/settings')}}>
                            <FontAwesomeIcon icon={faGear} />
                        </ClickableArea> :
                        <HeaderBackTo target={getHeaderInfos().target} title={getHeaderInfos().title}/>}
                </Col>
                <Col sm={4} className="d-flex justify-content-center pt-1">
                    <Logo/>
                </Col>
                <Col sm={4} className="d-flex justify-content-end pt-2 d-print-none">
                    <Button
                        onClick={() => {
                            navigate('/login/logout')
                        }}
                        text="Logout"
                        type={ButtonTypes.secondary}
                        size={ButtonSizes.sm}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default RaNavbar;
