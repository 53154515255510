import React, {useState, useEffect} from 'react';
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import {useNavigate} from 'react-router-dom';
import {H1} from "../../components/common/Headlines/H1";
import {Container} from "react-bootstrap";
import Button, {ButtonTypes} from "../../components/common/Button";
import {toast} from "react-toastify";
import UserFields from "./components/UserFields";
import Footer from "../../components/common/Footer";

const UsersDetailPage = () => {
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')


    const {data: insertUserData, loading: insertUserLoading, refetch: insertUserRefetch} = useRequestData({
            url: '/users',
            errorToastMsg: "Fehler beim Anlegen des Benutzers. Möglicherweise existiert dieser Benutzername bereits. ",
            method: 'post',
            manual: true,
        }
    );
    useEffect(() => {
        if (insertUserData) {
            toast.success('Benutzer gespeichert');
            navigate('/benutzer')
        }
    }, [insertUserData])

    return (<div className="d-flex flex-column min-vh-100 justify-content-between">
            <Container style={{marginTop: "120px"}}>
                <H1>Neuen Benutzer anlegen</H1>

                <UserFields
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                />


            </Container>
            <Footer>
                <Container className="d-flex justify-content-between">
                    <Button
                        text="Abbrechen"
                        onClick={() => {
                            navigate('/benutzer')
                        }}
                        type={ButtonTypes.secondary}
                    />
                    <Button
                        text="Speichern"
                        onClick={() => {
                            insertUserRefetch({username, password})
                        }}
                        isLoading={insertUserLoading}
                        disabled={!username || !password}
                    />
                </Container>
            </Footer>
        </div>
    );
};

export default UsersDetailPage;
