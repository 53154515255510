import {createAsyncThunk} from "@reduxjs/toolkit";
import {getExceptionPayload} from "../../../../helpers/store";
import axios from "axios";

export const fetchUser = createAsyncThunk(
    'room/fetchUser',
    async (id, {rejectWithValue}) => {
        try {
            const response = await axios.get("/users/user");
            return response.data;
        } catch (exception) {
            return rejectWithValue(getExceptionPayload(exception));
        }
    });
