import styled from "styled-components";

const StyledFooter = styled.footer`
  background: var(--color-primary-light);
  width: 100%;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding: 40px 0;
  box-shadow: 0 4px 25px rgba(0, 0, 0, 0.25);
`
export default StyledFooter;
