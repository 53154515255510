import React from 'react';
import InputText from "../../../components/common/Inputs/InputText";

const UserFields = ({
                        username,
                        setUsername,
                        password,
                        setPassword,
                    }) => {
    return (
        <div>
            <InputText
                label="Benutzername"
                placeholder="Bitte Benutzernamen angeben"
                value={username}
                setValue={setUsername}
            />

            <InputText
                type="password"
                label="Passwort"
                value={password}
                placeholder="Zum Ändern bitte neues Passwort eingeben"
                setValue={setPassword}
            />
        </div>
    );
};

export default UserFields;
