import styled, {css} from "styled-components";
import {InputTextSizes} from "./index";

export const StyledInput = styled.input`
  width: 100%;
  border: none;
  color: var(--color-font);
  
  ${({$hasBorder}) => !$hasBorder && css`
    border: 2px solid var(--color-dark-gray);
    box-shadow: 0 4px 27px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    outline: none;
  `}

  ${({size}) => {
    if (size === InputTextSizes.sm) {
        return css`
        font-size: .875rem;
        padding: 0.3rem 0.5rem;
      `;
    }

    if (size === InputTextSizes.md) {
        return css`
        font-size: 1em;
        font-weight: normal;
        padding: 10px;
      `;
    }

    if (size === InputTextSizes.lg) {
        return css`
        font-weight: bold;
        font-size: 32px;
        padding: 20px 30px;
      `;
    }
}}
`;

export const StyledInputWrapper = styled.div`
  display: flex;
`;

export const StyledInputLabel = styled.div`
  font-size: 14px;
  color: var(--color-dark-gray);

  ${({size}) => {
    if (size === InputTextSizes.sm) {
        return css`
        padding: 5px 0.5rem 0 0.5rem;
      `;
    }

    if (size === InputTextSizes.md) {
        return css`
        padding: 5px 10px 0 10px;
      `;
    }

    if (size === InputTextSizes.lg) {
        return css`
        padding: 5px 30px 0 30px;
      `;
    }
}}`;
