import {createSlice} from '@reduxjs/toolkit'
import {fetchUser} from "./thunks"

export const slice = createSlice({
    name: 'user',
    initialState: {
        data: undefined,
        loading: false,
        error: undefined,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.loading = true;
                state.data = undefined;
                state.error = undefined;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.data = undefined;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
                state.error = undefined;
            })
    },
})

export default slice.reducer
