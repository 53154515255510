// eslint-disable-next-line no-unused-vars
const InternalError = {
    message: "Internal Error during request.",
    code: -500,
};

export const getExceptionPayload = (exception) => {
    return {
        message: exception.response?.message || "Serverfehler",
        status: exception.response?.status || 500,
    }
};

// const UnhandledError = {
//   message: "Cannot handle error data",
//   code: -400,
// };

// const InternalServerError = {
//   message: "Internal Server Error",
//   code: 500,
// };

// const NotFoundError = {
//   message: "Not Found",
//   code: 404,
// };

// const ForbiddenError = {
//   message: "Forbidden",
//   code: 403,
// };

// const UnauthorizedError = {
//   message: "Unauthorized",
//   code: 401,
// };
