import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import styled from "styled-components";
import InputText, {InputTextSizes} from "../../Inputs/InputText";

const StyledFilterDropdown = styled.div`
  background: white;
  width: 250px;
  height: 350px;
  overflow: auto;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: 15px;
`

const FilterDropdown = forwardRef(({
                                       uniqueValues,
                                       selectedValues,
                                       handleCheckboxChange,
                                       handleInputChange,
                                       handleInputEnter
                                   }, ref) => {
    const inputRef = useRef();
    const [value, setValue] = React.useState('');

    useImperativeHandle(ref, () => ({
        focusInput: () => {
            setTimeout(() => {
                inputRef.current.focus();
            }, 500)
        }
    }));

    return (
        <StyledFilterDropdown>
            <InputText
                ref={inputRef}
                value={value}
                setValue={(e) => {
                    handleInputChange(e);
                    setValue(e);
                }}
                onPressEnter={handleInputEnter}
                hasBorder={false}
                size={InputTextSizes.sm}
                className="mb-2"
            />

            {uniqueValues.map(value => (<div key={value}>
                    <label className="d-flex">
                        <input
                            type="checkbox"
                            checked={selectedValues.includes(value)}
                            onChange={() => handleCheckboxChange(value)}
                            className="me-1"
                        />
                        <div>{value}</div>
                    </label>
                </div>
            ))}
        </StyledFilterDropdown>
    );
});

export default FilterDropdown;
