import styled from "styled-components";

export const StyledModal = styled.div`
  .modal-fullscreen {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0; /* Remove border-radius for full-screen */
  }

  .modal-fullscreen .modal-content {
    height: 100%;
    border: none; /* Remove borders if needed */
  }

`
