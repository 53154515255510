import React from 'react';
import {flexRender} from "@tanstack/react-table";

const Tbody = ({table}) => {
    return (<tbody>
    {table.getRowModel().rows.map(row => (
        <tr key={row.id} className="py-2">
            {row.getVisibleCells().map(cell => {
                return (
                    <td key={cell.id} className={cell.column.columnDef.className}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                )
            })}
        </tr>
    ))}
    </tbody>);
};

export default Tbody;
