import styled from "styled-components";
import React from 'react';

const StyledText = styled.span`
  font-weight: ${({$isBold}) => ($isBold ? 'bold' : 'default')};
  color: ${({$color = 'black'}) => $color};
  font-size: ${({$fontSize}) => $fontSize};
  display: ${({$isBlock}) => $isBlock ? 'block' : 'inline-block'};
  text-decoration: ${({$isUnderlined}) => $isUnderlined ? 'underline' : 'none'};
`;


const Text = ({
                  children,
                  className = "",
                  isBold,
                  color,
                  fontSize = '1rem',
                  isBlock,
                  isUnderlined
              }) => {
    return (
        <StyledText
            $isBold={isBold}
            $color={color}
            $fontSize={fontSize}
            $isBlock={isBlock}
            $isUnderlined={isUnderlined}
            className={className}
        >
            {children}
        </StyledText>
    );
};

export default Text;
