import {useGetUser} from "../store/user/hooks";
import React, {useEffect} from "react";
import {RouteWrapper} from "./RouteWrapper";

export const ProtectedRoute = ({children}) => {
    const {user, error: userError} = useGetUser();

    useEffect(() => {
        if (userError) {
            window.location.href = process.env.PUBLIC_URL + "/#/login";
        }
    }, [userError])

    return <RouteWrapper>{user ? children : null}</RouteWrapper>
}
