import React, {useEffect} from 'react';
import {H1} from "../../components/common/Headlines/H1";

const LogoutPage = () => {
    useEffect(() => {
        window.localStorage.removeItem('loginToken');
        window.location.href = '#/login'
    }, []);

    return (
        <div>
            <H1>Sie werden ausgeloggt..</H1>
        </div>
    );
};

export default LogoutPage;
