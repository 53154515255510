import React, {useRef} from 'react';
import Select from 'react-select';
import styled from "styled-components";
import ListItem from "../ListItem";
import Text from "../Text";
import PropTypes from 'prop-types';
import {validationPropTypes} from "../Validation";
import DropdownValidation from "./DropdownValidation";

// https://www.npmjs.com/package/react-select

/*
pass e.g.
const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];
 */

const StyledDropdown = styled.div`
  > div > div {
    border: none;
    box-shadow: none;
  }
`

export const DropdownSizes = {
    sm: 'sm',
    md: 'md',
    lg: 'lg',
}

const Dropdown = ({
                      options,
                      selectedValue,
                      setSelectedValue,
                      placeholder = "Bitte eine Auswahl treffen",
                      size = DropdownSizes.md,
                      className = "",
                      label = "",
                      isSearchable = false,
                      isMulti = false,
                      isLoading = false,
                      validation = {},
                  }) => {
    const validationRef = useRef();

    const refreshValidState = (v) => {
        if (validationRef.current) {
            validationRef.current.onValueChange(v);
        }
    };

    return (<>
            <ListItem className={`${className}`}>
                {label && <Text color="var(--color-dark-gray)" fontSize="14px" className="ps-2">{label}</Text>}
                <StyledDropdown>
                    <Select
                        value={selectedValue}
                        onChange={(v) => {
                            setSelectedValue(v);
                            refreshValidState(v);
                        }}
                        options={options || []}
                        placeholder={placeholder}
                        isSearchable={isSearchable}
                        isMulti={isMulti}
                        isLoading={isLoading}
                        styles={{
                            container: (styles) => ({
                                ...styles,
                                padding: size === DropdownSizes.lg ? "18px 5px 18px 22px" : 'inherit',
                            }),
                            menuList: (styles) => ({
                                ...styles,
                                boxShadow: "var(--box-shadow)",
                            }),
                            control: (styles) => ({
                                ...styles,
                                boxShadow: "none",
                                fontSize: size === DropdownSizes.lg ? "30px" : 'inherit',
                                //fontWeight: "bold",
                            }),
                            dropdownIndicator: (styles) => ({
                                ...styles,
                                color: "var(--color-font)",
                            }),
                            indicatorSeparator: (styles) => ({
                                ...styles,
                                display: "none"
                            }),
                            option: (styles, {isFocused, isSelected}) => ({
                                ...styles,
                                cursor: "pointer",
                                backgroundColor: isSelected || isFocused ? "var(--color-primary-light)" : "var(--color-white)",
                                color: "var(--color-font)",
                            }),
                            placeholder: (styles) => ({
                                ...styles,
                                color: "var(--color-font)",
                            })
                        }}
                    />
                </StyledDropdown>
                <DropdownValidation value={selectedValue} validation={validation} ref={validationRef}/>
            </ListItem></>
    );
};

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
    })).isRequired,
    selectedValue: PropTypes.oneOfType([
        // An array of objects with the specified shape
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                label: PropTypes.string,
            })
        ),
        // A single object with the specified shape
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            label: PropTypes.string,
        }),
    ]),
    setSelectedValue: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    size: PropTypes.oneOf(Object.values(DropdownSizes)),
    className: PropTypes.string,
    label: PropTypes.string,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    isLoading: PropTypes.bool,
    validation: PropTypes.shape(validationPropTypes),
};

export default Dropdown;
