import React from 'react';
import logoIntakt from "../../../assets/img/intakt.png";
import Text from "../../common/Text";

const Logo = () => {
    return (
        <div className="d-flex align-items-baseline">
            {/*<img src={logoIntakt} style={{height: "50px"}} alt="intakt" />*/}
            <div style={{position: "relative", width: "50px", height: "42px"}}>
                <div style={{position: "absolute", top: "1px"}}>
                    <Text fontSize="35px">Mitarbeiterplanung</Text>
                </div>
            </div>
        </div>
    );
};

export default Logo;
