import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const RouteWrapper = ({children}) => {
    const navigate = useNavigate();

    useEffect(() => {
        // if too long on page -> redirect to home page
        const timeout = setTimeout(() => {
            navigate("/")
        }, 2 * 60 * 60 * 1000) // 2h
        return () => {
            clearTimeout(timeout);
        }
    }, [])

    return children;
}
