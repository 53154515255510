import React from 'react';
import NotFoundLottie from "./not_found.lottie.json";
import {useNavigate} from "react-router-dom";
import {Container} from "react-bootstrap";
import {H3} from "../../common/Headlines/H3";
import Lottie from "lottie-react";
import Footer from "../../common/Footer";
import Button from "../../common/Button";

const NotFoundPage = () => {
    const navigate = useNavigate()

    return (
        <div className="d-flex flex-column min-vh-100 pt-5">
            <Container className="flex-grow-1 text-center">
                <H3>Diese Seite konnte nicht gefunden werden. </H3>


                <div className="d-flex justify-content-center">
                    <Lottie
                        animationData={NotFoundLottie}
                        loop={false}
                    />
                </div>
            </Container>
            <Footer>
                <Container className="d-flex justify-content-end">
                    <Button
                        text="Zur Startseite"
                        onClick={() => {
                            navigate(`/`)
                        }}
                    />
                </Container>
            </Footer>
        </div>
    );
};

export default NotFoundPage;
