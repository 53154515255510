import AdminLoginPage from "./pages/Login";
import AdminDashboardPage from "./pages/Dashboard";
import UsersOverviewPage from "./pages/Users/UsersOverviewPage";
import UsersCreatePage from "./pages/Users/UsersCreatePage";
import UsersDetailPage from "./pages/Users/UsersDetailPage";
import LogoutPage from "./pages/Login/LogoutPage";
import React from "react";
import NotFoundPage from "./components/misc-shared/NotFoundPage";
import {ApplicationWrapperContainer} from "./components/misc-shared/Routing/ApplicationWrapperContainer";
import {RouteWrapper} from "./components/misc-shared/Routing/RouteWrapper";
import {ProtectedRoute} from "./components/misc-shared/Routing/ProtectedRoute";
import SettingsPage from "./pages/Settings";
import ManageSchedulesPage from "./pages/ManageSchedulesPage";

export const routes = [
    {
        path: "/login",
        element:
            <RouteWrapper><ApplicationWrapperContainer
                showNavbar={false}><AdminLoginPage/></ApplicationWrapperContainer></RouteWrapper>,
    },
    {
        path: "/",
        element:
            <RouteWrapper><ApplicationWrapperContainer><AdminDashboardPage/></ApplicationWrapperContainer></RouteWrapper>,
    },
    {
        path: "/planungsassistent/:type",
        element:
            <RouteWrapper><ApplicationWrapperContainer><ManageSchedulesPage/></ApplicationWrapperContainer></RouteWrapper>,
    },
    {
        path: "/benutzer",
        element:
            <ProtectedRoute><ApplicationWrapperContainer><UsersOverviewPage/></ApplicationWrapperContainer></ProtectedRoute>,
    },
    {
        path: "/benutzer/neu",
        element:
            <ProtectedRoute><ApplicationWrapperContainer><UsersCreatePage/></ApplicationWrapperContainer></ProtectedRoute>,
    },
    {
        path: "/benutzer/:id",
        element:
            <ProtectedRoute><ApplicationWrapperContainer><UsersDetailPage/></ApplicationWrapperContainer></ProtectedRoute>,
    },
    {
        path: "/settings",
        element:
            <ProtectedRoute><ApplicationWrapperContainer><SettingsPage/></ApplicationWrapperContainer></ProtectedRoute>,
    },
    {
        path: "/login/logout",
        element: <RouteWrapper><ApplicationWrapperContainer
            showNavbar={false}><LogoutPage/></ApplicationWrapperContainer></RouteWrapper>,
    },
    {
        path: "*",
        element: <RouteWrapper><ApplicationWrapperContainer
            showNavbar={false}><NotFoundPage/></ApplicationWrapperContainer></RouteWrapper>,
    },
]
