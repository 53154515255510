import React from 'react';
import PropTypes from "prop-types";

const ClickableArea = ({children, handleClick = ()=>{}, className=""}) => {
    return (
        <div role="button" onClick={handleClick} className={className}>
            {children}
        </div>
    );
};

ClickableArea.propTypes = {
    children: PropTypes.node.isRequired,
    handleClick: PropTypes.func,
    className: PropTypes.string,
};

export default ClickableArea;
