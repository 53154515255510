import {createSlice} from '@reduxjs/toolkit'
import {fetchAppInfo} from "./thunks"
import moment from "moment";

export const slice = createSlice({
    name: 'appInfo',
    initialState: {
        data: {
            version: undefined,
        },
        devModeIsActive: window.localStorage.getItem('devMode') && moment(window.localStorage.getItem('devMode')).isSame(new Date(), "day"),
        loading: false,
        error: undefined,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            // get
            .addCase(fetchAppInfo.pending, (state) => {
                state.loading = true;
                state.data = undefined;
                state.error = undefined;
            })
            .addCase(fetchAppInfo.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.data = undefined;
            })
            .addCase(fetchAppInfo.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false;
                state.error = undefined;
            })
    },
})

export default slice.reducer
