import React, {useState, useMemo} from 'react';
import {
    getCoreRowModel,
    useReactTable,
    getSortedRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
} from '@tanstack/react-table'
import styled from "styled-components";
import {filterArrIncludes} from "./filterFuntions";
import PropTypes from 'prop-types';
import TablePagination from "./Pagination";
import Thead from "./Thead";
import Tbody from "./Tbody";
import Text from "../Text";
import Lottie from "lottie-react";
import NoResultsLottie from "./no-results.lottie.json";

const StyledTable = styled.div`
  max-width: 100%;
  // overflow-x: auto; rausgenommen wegen filter dropdown
`;

const Table = ({columns, data, pagination = false, noDataMessage = "Es liegen keine Daten vor. "}) => {
    const defaultPaginationParams = {
        pageIndex: 0,
        pageSize: 40,
    };

    const [sorting, setSorting] = React.useState([])
    const [columnFilters, setColumnFilters] = React.useState([]);
    const [paginationState, setPaginationState] = useState(
        typeof pagination === 'boolean' && pagination ? defaultPaginationParams : pagination
    );

    const defaultColumn = useMemo(() => ({
        filterFn: filterArrIncludes, // Set filterArrIncludes as the default filter function
    }), []);


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        onSortingChange: setSorting,
        onColumnFiltersChange: setColumnFilters,
        state: {
            sorting,
            columnFilters,
            pagination: paginationState,
        },
        filterFns: {},
        getPaginationRowModel: pagination && getPaginationRowModel(),
        onPaginationChange: setPaginationState,
        // autoResetPageIndex: false, // turn off page index reset when sorting or filtering
        defaultColumn,
    })

    if (!data || data.length === 0) {
        return <div className="d-flex justify-content-center align-items-center flex-column mt-5">
            <Lottie
                animationData={NoResultsLottie}
                loop={false}
                style={{width: 200, opacity:.5}}
            />
            <Text className="text-center">
                {noDataMessage}
            </Text>
        </div>
    }

    return (<StyledTable>
            {pagination && <TablePagination table={table}/>}
            <table className="table table-striped table-responsive">
                <Thead table={table}/>
                <Tbody table={table}/>
            </table>
        </StyledTable>
    );
};


Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        columnDef: PropTypes.shape({
            header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            cell: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
            className: PropTypes.string,
        }),
        getCanFilter: PropTypes.func,
        getCanSort: PropTypes.func,
        getToggleSortingHandler: PropTypes.func,
        getIsSorted: PropTypes.func,
    })).isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            pageIndex: PropTypes.number,
            pageSize: PropTypes.number,
        }),
    ]),
    noDataMessage: PropTypes.string,
};
export default Table;
