import React, {useEffect, useState} from 'react';
import {H2} from "../../components/common/Headlines/H2";
import UserManagement from "./components/UserManagement";
import {Col, Row} from "react-bootstrap";
import DraggableItem from "./components/DraggableItem";
import StationManagement from "./components/StationManagement";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import ClickableArea from "../../components/common/ClickableArea";
import {getMondayOfWeek} from "../../services/date.service";
import useRequestData from "../../components/misc-shared/store/hooks/requestData";
import Button from "../../components/common/Button";

const OrganizeWeekdays = ({calendarWeek}) => {
    const [employeesResponse, setEmployeesResponse] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [workstations, setWorkstations] = useState([]);
    const [weekday, setWeekday] = useState(getMondayOfWeek(calendarWeek))
    const [availability, setAvailability] = useState();

    useEffect(() => {
        setWeekday(getMondayOfWeek(calendarWeek))
    }, [calendarWeek])
    const getShortWeekday = () => {
        return moment(weekday).format('ddd').toLowerCase().replace('.', '')
    }

    const {loading: loadingAvailability} = useRequestData({
        url: '/availability/' + calendarWeek.week + '-' + calendarWeek.year,
        onRequestSuccess: setAvailability,
        errorToastMsg: 'Fehler beim Laden der Verfügbarkeiten. '
    })
    const {refetch: updateAvailability} = useRequestData({
        url: '/availability',
        onRequestSuccess: (e) => {
            fetchEmployees()
            setAvailability(e)
        },
        method: 'patch',
        manual: true,
        errorToastMsg: 'Fehler beim Speichern der Verfügbarkeiten. '
    })
    const {refetch: deleteAvailability} = useRequestData({
        url: '/availability/delete',
        onRequestSuccess: (e) => {
            fetchEmployees()
            setAvailability(e)
        },
        method: 'patch',
        manual: true,
        errorToastMsg: 'Fehler beim Löschen der Verfügbarkeit. '
    })

    const onDragEmployee = (employeeId, stationId) => {
        updateAvailability({
            calendarWeek,
            employeeId,
            stationId,
            weekday: getShortWeekday()
        })
    }

    const onDeleteEmployee = (availabilityEntry) => {
        deleteAvailability({
            calendarWeek,
            availabilityId: availabilityEntry.id,
            weekday: getShortWeekday()
        })
    }

    const {loading: loadingEmployees, refetch: fetchEmployees} = useRequestData({
        url: `/employees?calendar_week=${calendarWeek.week}-${calendarWeek.year}&weekday=${getShortWeekday()}`,
        onRequestSuccess: (data) => {
            setEmployees(data);
            setEmployeesResponse(data);
        },
        errorToastMsg: 'Fehler beim Laden der Mitarbeiter. '
    })

    const {loading: loadingWorkstations} = useRequestData({
        url: '/workstations',
        onRequestSuccess: setWorkstations,
        errorToastMsg: 'Fehler beim Laden der Arbeitsplätze. '
    })

    const [assignments, setAssignments] = useState([]);
    useEffect(() => {
        if (weekday && availability) {
            setAssignments(availability.assignments[getShortWeekday()])
        }
    }, [weekday, availability])

    if (loadingEmployees || loadingWorkstations || loadingAvailability) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <div className="d-flex">
                {(moment(weekday).format('dddd') !== 'Montag') && <ClickableArea handleClick={() => {
                    setWeekday(moment(weekday).subtract(1, 'day'))
                }} className="d-print-none">
                    <FontAwesomeIcon icon={faArrowLeft} size="2x" className="me-3"/>
                </ClickableArea>}
                <H2>{moment(weekday).format('dddd, DD.MM.YYYY')}</H2>
                {moment(weekday).format('dddd') !== 'Sonntag' && <ClickableArea handleClick={() => {
                    setWeekday(moment(weekday).add(1, 'day'))
                }} className="d-print-none">
                    <FontAwesomeIcon icon={faArrowRight} size="2x" className="ms-3"/>
                </ClickableArea>}
            </div>

            <div className="d-flex justify-content-end mb-5 d-print-none">
                <Button onClick={() => {
                    alert("Feature kommt bald. ")
                }} text="Zuteilung generieren" className="me-3"/>
                <UserManagement employees={employeesResponse} setEmployees={setEmployees}/>
            </div>

            <Row className="mb-5 d-print-none">
                {employees.map(e => <Col sm={2}><DraggableItem
                    name={e.name}
                    id={e.id}
                    hoursMetaInfos={{
                        hoursPerWeek: e.weeklyHours,
                        spentHoursPerWeek: e.hoursSpentInWeek,
                        spentHoursPerDay: e.hoursSpentToday,
                    }}
                /></Col>)}
            </Row>

            <StationManagement
                workstations={workstations}
                onDragEmployee={onDragEmployee}
                onDeleteEmployee={onDeleteEmployee}
                assignments={assignments}
            />
        </div>
    );
};

export default OrganizeWeekdays;
