import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {Colors} from "../../../theme";

const StyledJumbotron = styled.div`
  background: ${Colors.backgroundGrey};
  border-radius: var(--border-radius);
  padding: 20px 15px;
  margin-bottom: 15px;
`

const Jumbotron = ({children, className="", style={}}) => {
    return (
        <StyledJumbotron className={className} style={style}>
            {children}
        </StyledJumbotron>
    );
};

Jumbotron.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Jumbotron;
