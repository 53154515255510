import React from 'react';
import {Container} from "react-bootstrap";

const ContentWrapper = ({children,className="",fluid = false}) => { // use this everywhere
    return (
        <Container fluid={fluid} className={`container-wrapper mt-5 flex-grow-1 ${className}`}>
            <style>
                {`@media print {.container-wrapper{max-width: 100%;}}`}
            </style>
            {children}
        </Container>
    );
};

export default ContentWrapper;
