import React, {useEffect, useState} from 'react';
import {useDrop} from 'react-dnd';
import {Colors} from "../../../theme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const StyledDroppableBox = styled.div`
  width: 150px;
  min-height: 43px;
  border: ${({hasDropped}) => hasDropped ? 'none' : '1px dotted ' + Colors.darkGray};
  background-color: ${({isOver}) => isOver ? Colors.primaryLight : 'transparent'};

  @media print {
    border: none;
    width: 100%;
  }
`

const StyledDroppableBoxItemWrapper = styled.div`
  padding: 8px;
  border: 1px solid ${Colors.darkGray};
  position: relative;

  @media print {
    border: none;
    padding: 0;
  }
`

const StyledDroppableBoxItem = styled.div`
  background: ${Colors.primary};
  color: ${Colors.white};
  position: absolute;
  border-radius: 50%;
  padding: 0 4px;
  line-height: 21px;
  top: -10px;
  right: -10px;
  cursor: pointer;
`

const DroppableBox = ({children, items, onDragEmployee, onDeleteEmployee}) => {
    const [hasDropped, setHasDropped] = useState(false);

    const [{isOver}, dropRef] = useDrop({
        accept: 'ITEM',
        drop: (item) => {
            onDragEmployee(item.id);
        },
        collect: (monitor) => {
            return ({
                isOver: monitor.isOver(),
            })
        },
    });

    useEffect(() => {
        if (items.length) {
            setHasDropped(true)
        } else {
            setHasDropped(false)
        }
    }, [items])

    return (
        <StyledDroppableBox
            ref={dropRef}
            isOver={isOver}
            hasDropped={hasDropped}
        >
            {items.map((item, index) => (
                <StyledDroppableBoxItemWrapper key={index}>
                    <StyledDroppableBoxItem
                        onClick={() => {
                            onDeleteEmployee(item)
                        }}
                        className="d-print-none">
                        <FontAwesomeIcon icon={faTimes}/>
                    </StyledDroppableBoxItem>
                    {item.employee.name}
                </StyledDroppableBoxItemWrapper>
            ))}
            {children}
        </StyledDroppableBox>
    );
}

export default DroppableBox;
