import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import Text from "../Text";
import PropTypes from "prop-types";
import {validationPropTypes} from "../Validation";

const DropdownValidation = forwardRef(({
                                           value,
                                           validation: {
                                               required = false,
                                               setValid = () => {
                                               },
                                               errorMessage = 'Dieses Feld ist fehlerhaft. ',
                                               validateOnInit = false,
                                           }
                                       }, ref) => {
    useEffect(() => {
        if (validateOnInit) {
            setValid(isValid())
        }
    }, [])

    const isValid = (newValue = value) => {
        if (Array.isArray(newValue)) {
            return newValue.length > 0;
        }

        if (typeof newValue === 'object' && newValue) {
            return Object.keys(newValue).length > 0;
        }

        return !!newValue;
    }

    useImperativeHandle(ref, () => ({
        onValueChange(value) {
            setValid(isValid(value))
        },
    }));

    if (!required) {
        return null;
    }

    return !isValid() && <Text className="ps-2" fontSize="14px" color="var(--color-danger)">{errorMessage}</Text>
});

DropdownValidation.propTypes = {
    value: PropTypes.oneOfType(PropTypes.array, PropTypes.object),
    validation: PropTypes.shape(validationPropTypes),
};

export default DropdownValidation;
